.App {
  text-align: center;
  color: #181A1B;
}


body {
  padding: 0;
  margin: 0;
  background-color: #DAE3E7;
  overflow-x:hidden;
  max-width: 100%;
  font-size: 18px;
  margin-bottom: 40px;

 

}

.home{

}

.aboutCon{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
}

.aboutMe{
  text-align: left;
}

.aboutIcon{
  padding: 10px;
  max-height: 50px;
  max-width: 100px;
}

.demoBtn{
  margin-left: 5px;
}

.homeElement{
  background-color: #FFFFFF;
  margin: 10px;
  margin-left: 5%;
  margin-right: 5%;

  border-radius: 4px;

}

.linkedIn{
  filter: grayscale(1);
}

.linkedIn:hover{
  filter: grayscale(0);
}

.gitHub:hover{
  filter: contrast(.5)
}

.profilePhoto{
  width: 100px;
  border-radius: 50%;
}

.carCol{
  max-width: 450px;
}

.content{
  max-width: 1200px;
}

.language{
  font-size: 32px;
}

.contactBtn{
  font-size: 24px;
}

.push-down{
  margin-top: 20px;
}

a:link {
  text-decoration: none;
}